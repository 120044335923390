<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div id="body-content-area">
        <div v-if="isLoading">
          <Loading/>
        </div>
        <div v-else id="pagelayout">
          <form @submit.prevent="handleSubmitLocal">
            <div class="columns" id="etp">
              <div class="column">
                <div
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Email Template Properties</span>
                </div>
                <div class="block">
                  <div class="field is-grouped">
                    <div class="field">
                      <label class="label is-size-7">
                        Template Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.name">
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Template Type
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <select class="input" type="text" name="category" v-model="input.emailTemplateTypeCategoryId"  @change="getTokens(input.emailTemplateTypeId, input.emailTemplateTypeCategoryId)">
                          <option v-for="category in templateTypeCategories.filter(x => ['Reporting All', 'Reporting Questionnaire'].includes(x.description))"
                                  :key="category.emailTemplateTypeId"
                                  :value="category.id">
                            {{category.description}}
                            </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns" v-if="maxHeight > 0">
              <div class="column">
                <div
                  class="block"
                  id="etc"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Email Template Content</span>
                </div>
                <div class="block">
                  <div class="field is-grouped" id="esep">
                    <div class="field">
                      <label class="label is-size-7" id="label">Email Subject</label>
                      <div class="control">
                        <input
                          @click="changeFocus('subject')"
                          @onfocus="changeFocus('subject')"
                          @onselect="changeFocus('subject')"
                          class="input"
                          type="text"
                          name="name"
                          v-model="input.subject"
                          size="75"
                          ref="subject"
                        >
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">Email Personalization</label>
                      <div class="control">
                        <div class="select">
                          <select @change="personalizationChange" v-model="selectedToken">
                            <option selected>Personalize the template</option>
                            <option v-for="token in tokens"
                                    :key="token.value"
                                    :value="token.value">
                              {{token.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label
                      class="label is-size-7"
                      @click="changeFocus('body')"
                      @onfocus="changeFocus('body')"
                      @onselect="changeFocus('body')"
                      style=" top: 33px"
                    >Email Body</label>
                    <div class="control" :style="{ maxHeight: maxHeight + 'px', height: maxheight + 'px'}">
                      <tinymce
                        id="editor"
                        :init="{
                          height: maxHeight
                        }"
                        v-model="input.body"
                        ref="editor"
                        @onClick="changeFocus('body')"
                        plugins="code"
                        toolbar="code"
                        @click="changeFocus('body')"
                        @onfocus="changeFocus('body')"
                        @onselect="changeFocus('body')"
                        @editorChange="changeFocus('body')"
                        @input="changeFocus('body')"
                      ></tinymce>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="buttongroup">
              <div class="field is-grouped">
                <div class="control">
                  <button :disabled="!$hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 2)" type="submit" class="button is-accent has-text-white">Save</button>
                </div>
                <div class="control">
                  <a class="button is-light" @click="resetForm();$router.go(-1)">Cancel</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import Loading from './Loading'
let pageName = 'Email Template Details'

export default {
  components: {
    Loading
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          siteId: 0,
          emailTemplateTypeId: 2, // Default to reporting as Account templates require coding to work upon creation
          emailTemplateTypeCategoryId: 5,
          name: null,
          subject: null,
          body: null,
          type: null,
          createdAt: null,
          updatedAt: null,
          emailTemplateType: 'Reporting',
          emailTemplateTypeCategory: 'Reporting All'
        }
      }
    }
  },
  data () {
    return {
      input: {
        id: 0,
        siteId: 0,
        emailTemplateTypeId: 2, // Default to reporting as Account templates require coding to work upon creation
        emailTemplateTypeCategoryId: 5,
        name: '',
        subject: '',
        body: '',
        type: null,
        createdAt: '',
        updatedAt: null,
        emailTemplateType: 'Reporting',
        emailTemplateTypeCategory: 'Reporting All'
      },
      focus: 'body',
      customToolbar: [['bold', 'italic', 'underline']],
      inputDefault: {},
      isLoading: false,
      templateTypes: [
        {
          id: 1,
          key: 'AccountAccess',
          name: 'Account Access'
        },
        {
          id: 2,
          key: 'Reporting',
          name: 'Reporting'
        }
      ],
      templateTypeCategories: [],
      tokens: [],
      pageheading: pageName,
      selectedToken: 'Personalize the template',
      take: 0,
      perPage: 0,
      minResults: 0,
      maxHeight: 0
    }
  },
  watch: {
    input: {
      handler: function (val, oldVal) {
        if (val.emailTemplateTypeCategoryId !== oldVal.emailTemplateTypeCategoryId) {
          this.getTokens(this.input.emailTemplateTypeId, this.input.emailTemplateTypeCategoryId)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      return !id || id <= 0
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '#esep',
        '#buttongroup',
        '#etp',
        '#etc',
        '#header-area',
        '#label'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight - 100

        this.setupForm(this.model)
        await this.getTokens(this.model.emailTemplateTypeId, this.model.emailTemplateTypeCategoryId)
        await this.getEmailTemplateTypeCategories()
      }

      that.getListHeights('body-content-area', 40, 300, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 79 + 43.8, elements, func.bind(that))
      })
    })
  },
  methods: {
    changeFocus (focus) {
      this.focus = focus
    },
    async handleSubmitLocal () {
      // this.input.body = this.input.body.replaceAll('<div><br></div>', '<br>').replaceAll('<p><br></p>') // hack to deal with quil https://github.com/quilljs/quill/issues/1074
      let payload = new FormData()

      payload.append('emailTemplate.Id', this.input.id)
      payload.append('emailTemplate.SiteId', this.input.siteId)
      payload.append('emailTemplate.EmailTemplateTypeId', this.input.emailTemplateTypeId)
      payload.append('emailTemplate.EmailTemplateTypeCategoryId', this.input.emailTemplateTypeCategoryId)
      payload.append('emailTemplate.Name', this.input.name)
      payload.append('emailTemplate.Subject', this.input.subject)
      payload.append('emailTemplate.Body', this.input.body)

      payload.append('emailTemplate.Type', this.input.type)
      payload.append('emailTemplate.CreatedAt', this.input.createdAt)
      payload.append('emailTemplate.UpdatedAt', this.input.updatedAt)
      payload.append('emailTemplate.EmailTemplateType', this.input.emailTemplateType)
      payload.append('emailTemplate.EmailTemplateTypeCategory', this.input.emailTemplateTypeCategory)

      try {
        await this.$http.post('/email-templates', payload)
        this.$router.go(-1)
        this.successToast('Saved!')
      } catch (error) {
        this.handleApiErr(error, undefined, `The Email Template '${this.input.name}' already exists. Please enter a unique name.`)
      } finally {
        this.isLoading = false
      }
    },
    async getTokens (emailTemplateTypeId, emailTemplateTypeCategoryId) {
      this.tokens = []
      try {
        let route = `/email-templates/tokens`
        if (emailTemplateTypeId && emailTemplateTypeCategoryId) {
          route = `/EmailTemplates/TemplateType/${emailTemplateTypeId}/TemplateTypeCategory/${emailTemplateTypeCategoryId}/tokens`
        }
        let response = await this.axios.get(route)
        this.tokens = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
      }
    },
    async getEmailTemplateTypeCategories () {
      try {
        const route = `/EmailTemplates/TemplateTypeCategories`

        let response = await this.axios.get(route)
        this.templateTypeCategories = response.data
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    personalizationChange (event) {
      let token = event.target.value
      if (this.focus === 'subject') {
        let startPos = this.$refs.subject.selectionStart
        let endPos = this.$refs.subject.selectionEnd
        this.input.subject =
          this.input.subject.substring(0, startPos) +
          token +
          this.input.subject.substring(endPos, this.input.subject.length)
      }
      if (this.focus === 'body') {
        this.$refs.editor.editor.execCommand('mceInsertContent', false, token)
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
input:-moz-read-only {
  background-color: #ccc;
}
input:read-only {
  background-color: #ccc;
}
</style>
